import React, { useEffect, useState } from "react";
import Styles from "./index.module.scss";
import CommonTitle from "../../components/CommonTitle";
import noDatafound from "../../assets/noData.png";
import Loading from "../../components/Loading";
import axios from "axios";
import CoursePakage from "../CourseVideo/CoursePackage";
import cardImg from "../../assets/c7.webp";
import { useNavigate } from "react-router-dom";

const MyCourses = () => {
  const [loading, setLoading] = useState(true);
  const [bookings, setBookings] = useState([]);
  let user = JSON.parse(localStorage.getItem("user"));
  let token = user.token;
  const navigate = useNavigate();
  useEffect(() => {
    const getCourse = {
      url: "https://admin.adhigyanam.com/api/getVideoSubscription",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(getCourse)
      .then((res) => {
        setBookings(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err)
      });

    // eslint-disable-next-line
  }, []);
  const onBuyCourseHandler = (id, item) => {
    navigate("/my-courses/courses-videos", { state: { courseId: id } });
  };
  return (
    <div className={Styles.bookingArea}>
      <div className={Styles.bookingArea__navBar}>
        <CommonTitle color="#155651" tittle="My Courses" />
      </div>
      <div className={Styles.bookingArea__bookingCardArea}>
        <>
          {loading ? (
            <div className={Styles.loder}>
              <Loading width="100%" />
            </div>
          ) : (
            <>
              {bookings.length === 0 ? (
                <div className={Styles.bookingArea__bookingCardArea__imageArea}>
                  <div className={Styles.bookingArea__bookingCardArea__imageArea__emptyMsg}>
                    <img src={noDatafound} alt="err" />
                  </div>
                </div>
              ) : (
                <>
                  {bookings.map((item, index) => {
                    return (
                      <div key={index} className={Styles.bookingArea__bookingCardArea__card}>
                        <CoursePakage
                          id={item.id}
                          title={item.course_package_title}
                          sub_title={item.course_package_subtitle}
                          src={item.thumbnail ? item.thumbnail : cardImg}
                          language={item.course_package_language}
                          price={item.is_subscribed === 1 ? "Paid" : item.course_package_price}
                          rating={item.course_package_ratings}
                          btnName={item.is_subscribed === 1 ? "View Course" : "Buy Now"}
                          onBuyCourseHandler={() => onBuyCourseHandler(item.id, item)}
                        />
                      </div>
                    );
                  })}
                </>
              )}
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default MyCourses;
