// import { useState, useEffect } from "react";
// import devtoolsDetect from "devtools-detect";

// export function useDevToolsStatus() {
//   const [isDevToolsOpen, setIsDevToolsOpen] = useState(devtoolsDetect.isOpen);

//   useEffect(() => {
//     const handleChange = (event) => {
//       setIsDevToolsOpen(event.detail.isOpen);
//     };

//     window.addEventListener("devtoolschange", handleChange);

//     return () => {
//       window.removeEventListener("devtoolschange", handleChange);
//     };
//   }, []);

//   return isDevToolsOpen;
// }

import { useState, useEffect } from "react";

const threshold = 150;

const useDevToolsDetect = () => {
  const [devToolsStatus, setDevToolsStatus] = useState({
    isOpen: false,
    orientation: undefined,
  });

  const emitEvent = (isOpen, orientation) => {
    const event = new CustomEvent("devtoolschange", {
      detail: { isOpen, orientation },
    });
    window.dispatchEvent(event);
  };

  const checkDevTools = () => {
    const widthThreshold = window.outerWidth - window.innerWidth > threshold;

    // Ignore minor height changes (like the ones caused by the address bar collapsing/expanding)
    const adjustedHeightThreshold = 180; // Adjust this threshold to your needs
    const heightDifference = window.outerHeight - window.innerHeight;
    const heightThreshold = heightDifference > threshold && heightDifference > adjustedHeightThreshold;

    const orientation = widthThreshold ? "vertical" : "horizontal";

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isDevToolsOpenInSafari = isSafari && /./.test(window.console) && console.debug.toString().length > 50;
    if (
      !(heightThreshold && widthThreshold) &&
      ((window.Firebug && window.Firebug.chrome && window.Firebug.chrome.isInitialized) ||
        widthThreshold ||
        heightThreshold ||
        isDevToolsOpenInSafari)
    ) {
      if (!devToolsStatus.isOpen || devToolsStatus.orientation !== orientation) {
        emitEvent(true, orientation);
        setDevToolsStatus({ isOpen: true, orientation });
      }
    } else {
      if (devToolsStatus.isOpen) {
        emitEvent(false, undefined);
        setDevToolsStatus({ isOpen: false, orientation: undefined });
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(checkDevTools, 500);

    return () => clearInterval(interval);
  }, [devToolsStatus]);

  return devToolsStatus;
};

export default useDevToolsDetect;
