import Logo from "../assets/LOGO.webp";

export function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export const displayRazorpay = async (amount) => {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
  if (!res) {
    alert("You are offline ...Failed to load Razorpay SDK", {
      type: "warning",
    });
    return;
  } else {
    const options = {
      key: "rzp_test_gZte4p2li4zBnk",
      currency: "INR",
      amount: amount * 100,
      name: "Adhigyanam",
      description: "Thanks for purchasing",
      image: Logo,
      prifill: {
        name: "Gaurav Kumar", //your customer's name
        email: "gaurav.kumar@example.com",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
      handler: function (response) {
        return response;
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
};

export const handlePayment = async (price) => {
  try {
    const response = await displayRazorpay(price);
    // Handle the response here
    return response;
  } catch (error) {
    // Handle any errors
    return error;
  }
};

// ****************************** Fetch Transaction Detail ****************************

// alert(response.razorpay_payment_id);
// alert("Payment Successfully");
// console.log(response, "payment response heref");
// if (response) {
//   console.log(response);
//   //   let urlData = {
//   //     url: `https://api.razorpay.com/v1/payments/${response?.razorpay_payment_id}`,
//   //     method: "GET",
//   //     headers: {
//   //       Authorization: "Basic " + btoa("rzp_test_gZte4p2li4zBnk:77P4POy1orG92oZUBisTHL1H"),
//   //     },
//   //     //   username: "rzp_test_gZte4p2li4zBnk",
//   //     //   password: "77P4POy1orG92oZUBisTHL1H",
//   //   };
//   //   axios(urlData)
//   const keyId = "rzp_test_gZte4p2li4zBnk";
//   const keySecret = "77P4POy1orG92oZUBisTHL1H";
//   const paymentId = response?.razorpay_payment_id;

//   const url = `https://api.razorpay.com/v1/payments/${paymentId}`;
//   const authHeader = `Basic ${btoa(`${keyId}:${keySecret}`)}`;

//   axios
//     .get(url, {
//       headers: {
//         Authorization: authHeader,
//       },
//     })
//     .then((res) => {
//       console.log(res);
//       return res;
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// } else {
//   alert("Sorry payment failed", { type: "error" });
// }
