import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { IoSend } from "react-icons/io5";

const Footer = ({ sendMessage, setMessage, message, tense, items, startChat, chatComplete }) => {
  const [loading, setLoading] = useState(true);
  const onGetMessage = (e) => {
    setMessage(e.target.value);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <div className={styles.footer}>
      {loading ? (
        <p style={{ color: "#fff" }}>Loading...</p>
      ) : (
        <>
          {tense === "past" && <p>You're Unable To Chat</p>}
          {tense === "future" && <p>You Will Able To Chat On {items.date}</p>}
          {tense === "present" && (
            <>
              {chatComplete ? (
                <p>You're Unable To Chat</p>
              ) : (
                <>
                  {tense === "present" && startChat ? (
                    <>
                      <div className={styles.footer__left}>
                        <input type="text" className={styles.footer__left__text} onChange={onGetMessage} value={message} />
                        {/* <div className={styles.footer__left__file}>
          <label htmlFor="file">
            <IoAttachOutline fontSize={20} />
          </label>
          <div>
            <input type="file" id="file" onChange={onGetFile} />
          </div>
        </div> */}
                      </div>
                      <div onClick={sendMessage} style={{ background: `${message.length < 1 ? "gray" : "#1eb2a6"}` }} className={styles.footer__right}>
                        <IoSend color="#fff" />
                      </div>
                    </>
                  ) : (
                    <p>You Will Able To Chat Today</p>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Footer;
