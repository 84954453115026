import React, { useContext, useEffect } from "react";
import style from "./index.module.scss";
import ModalLayout from "../../components/ModalLayout";
import ganesh from "../../assets/banside.webp";
import { FaCalendarDay } from "react-icons/fa";
// import Button from "../../widgets/Button";
import TimeSlot from "../../components/TimeSlot";
import { useState } from "react";
import axios from "axios";
import Loading from "../../components/Loading";
import swal from "sweetalert";
import { Context } from "../../utils/ContextStor";

const dayObj = [
  { id: 1, name: "Sunday" },
  { id: 2, name: "Monday" },
  { id: 3, name: "Tuesday" },
  { id: 4, name: "Wednesday" },
  { id: 5, name: "Thursday" },
  { id: 6, name: "Friday" },
  { id: 7, name: "Saturday" },
];

const CustomizeSlot = (props) => {
  const details = JSON.parse(localStorage.getItem("user"));
  // eslint-disable-next-line
  const [token, setToken, updatedUser, setUpdatedUser] = useContext(Context);

  const [allSlots, setAllSlots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [timeSlotArr, setTimeSlotArr] = useState([]);
  const [active, setActive] = useState("Sunday");
  const [start, setStart] = useState("12:00");
  const [end, setEnd] = useState("13:00");
  const [fecthSlot, setFetchslot] = useState("");
  const [timeSlotCheaker, setTimeSlotsChecker] = useState([]);
  const onSlotBookHandler = (day) => {
    setActive(day);
    setUpdate(false);
  };

  const startTime = (e) => {
    setStart(e.target.value);
    setEnd("");
  };
  const endTime = (e) => {
    setEnd(e.target.value);
  };

  const convertTime = (val) => {
    let h = parseInt(val.split(":")[0]);
    let hour = h === 0 ? 12 : h > 12 ? h - 12 : h;
    let updatedHour = hour < 10 ? "0" + hour : hour;
    let updatedMin = val.split(":")[1];
    let session = h >= 12 ? "PM" : "AM";

    let time = `${updatedHour}:${updatedMin} ${session}`;

    return time;
  };

  const convertTo24HourFormat = (val) => {
    let timeArray = val.split(":");
    let hour = parseInt(timeArray[0]);
    let minute = timeArray[1].split(" ")[0];
    let session = timeArray[1].split(" ")[1];

    if (session === "PM" && hour !== 12) {
      hour += 12;
    } else if (session === "AM" && hour === 12) {
      hour = 0;
    }

    let updatedHour = hour < 10 ? "0" + hour : hour;

    let time24Hour = `${updatedHour}:${minute}`;

    return time24Hour;
  };

  const createSlotHandler = (arr) => {
    const url = "https://admin.adhigyanam.com/api/save-time-slot";
    const formdata = new FormData();
    formdata.append("slots", JSON.stringify(arr));
    formdata.append("astrologer_id", details.id);
    const getConfig = {
      url,
      method: "post",
      headers: {
        Authorization: `Bearer ${details.token}`,
      },
      data: formdata,
    };
    setLoading(true);
    axios(getConfig)
      .then((res) => {
        setFetchslot("");
        if (res.data.status) {
          setAllSlots(res.data.data);
          setLoading(false);

          swal({
            title: "Good try!",
            text: res.data.message,
            timer: 2000,
            icon: "success",
            className: "swal",
          });
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        // console.log(err)
        setLoading(false);
      });
  };

  const onTimeSlotHandler = () => {
    // debugger;
    let startTime = convertTime(start);
    let endTime = convertTime(end);

    let tSlot = `${startTime}-${endTime}`;

    const newTimeSlotObj = [{ [active]: [tSlot] }];

    let st = convertTo24HourFormat(startTime);
    let en = convertTo24HourFormat(endTime);

    if (st < en) {
      const existingSlots = allSlots.find((obj) => Object.keys(obj)[0] === active);
      if (existingSlots) {
        let findSlot = existingSlots[active]?.find((item) => {
          let existingStart = convertTo24HourFormat(item.split("-")[0]);
          let existingEnd = convertTo24HourFormat(item.split("-")[1]);
          let newStart = convertTo24HourFormat(startTime);
          let newEnd = convertTo24HourFormat(endTime);

          return (
            (newStart > existingStart && newStart < existingEnd) ||
            (newEnd > existingStart && newEnd < existingEnd) ||
            newStart === existingStart ||
            newEnd === existingEnd
          );
        });

        if (findSlot) {
          swal({
            title: "Slot is already exist!",
            text: "Slot is already exist",
            timer: 2000,
            icon: "error",
            className: "swal",
          });
          return;
        }

        createSlotHandler(newTimeSlotObj);
      } else {
        createSlotHandler(newTimeSlotObj);
      }
    } else {
      swal({
        title: "End Time Is Less Than Start Time!",
        text: "End Time Is Less Than Start Time",
        timer: 2000,
        icon: "warning",
        className: "swal",
      });
    }
  };

  useEffect(() => {
    const url = "https://admin.adhigyanam.com/api/save-time-slot";
    const formdata = new FormData();

    formdata.append("slots", JSON.stringify(timeSlotArr));
    formdata.append("astrologer_id", details.id);

    const getConfig = {
      url,
      method: "post",
      headers: {
        Authorization: `Bearer ${details.token}`,
      },
      data: formdata,
    };
    axios(getConfig)
      .then((res) => {
        if (res.data.status) {
          setAllSlots(res.data.data);
          if (res.data.status === true) {
            setTimeSlotArr([]);
          }
          setLoading(false);
        } else {
        }
      })
      .catch((err) => {
        // console.log(err)
      });

    //eslint-disable-next-line
  }, [fecthSlot]);

  const onDailySlotBookHandler = (time, day) => { };

  const onDeletSlot = (itm, index) => {
    const existingItem = timeSlotArr.find((obj) => Object.keys(obj)[0] === active);

    if (existingItem) {
      const filteredSlot = existingItem[active].filter((item) => item !== itm);

      existingItem[active] = filteredSlot;
      setTimeSlotArr([...timeSlotArr]);
    }
  };
  // *****************************************************************

  useEffect(() => {
    const config = {
      url: `https://admin.adhigyanam.com/api/slot-checker`,
      method: "post",
      headers: {
        Authorization: `Bearer ${details?.token}`,
      },
      data: {
        astrologer_id: details.id,
        slot_date: active,
      },
    };
    axios(config)
      .then((res) => {
        setTimeSlotsChecker(res.data.data);
      })
      .catch((err) => {
        // console.log(err)
      });
    // eslint-disable-next-line
  }, [active]);

  const onDeletExistingSlot = (itm, item, select) => {
    setLoading(true);
    const copyAllSlots = [...allSlots];
    let findSlot = copyAllSlots.find((obj) => Object.keys(obj)[0] === select);
    let filterSlote = findSlot[select].filter((item) => item !== itm);
    // let removeSlote = findSlot[select].filter((item) => item === itm);
    findSlot[select] = filterSlote;
    // setAllSlots(copyAllSlots);

    // ********************Update Slots****************
    const formData = new FormData();
    formData.append("updated_slots", JSON.stringify(findSlot));
    const updtedData = {
      url: "https://admin.adhigyanam.com/api/update-timeslot",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    };
    axios(updtedData)
      .then((res) => {
        setFetchslot("1");
        swal("success", res.data.message, "success");
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err)
      });
  };
  return (
    <ModalLayout width="100%" onCloseHandler={props.onCloseHandler}>
      <div className={style.customizeSlot}>
        <div className={style.customizeSlot__left}>
          <div className={style.customizeSlot__left__top}>
            <h3>Customize Slot</h3>
            <p>See When Coaches Are Available And Book Slot Them</p>
          </div>
          <div className={style.customizeSlot__left__mid}>
            <div className={style.customizeSlot__left__mid__astro}>
              <img src={updatedUser?.profile_ic} alt="astrologer" />
            </div>
            <div className={style.customizeSlot__left__mid__astrodetails}>
              <h3>{updatedUser?.name}</h3>
              <p className={style.customizeSlot__left__mid__astrodetails__ex}>Exp : {updatedUser?.experience} Years</p>

              <h2>Detail</h2>
              <p className={style.customizeSlot__left__mid__astrodetails__desig}>{updatedUser?.speacility}</p>
            </div>
          </div>
          <div className={style.customizeSlot__left__bot}>
            <div className={style.customizeSlot__left__bot__image}>
              <img src={ganesh} alt="ganeshpic" />
            </div>
          </div>
        </div>
        <div className={style.customizeSlot__right}>
          <div className={style.customizeSlot__right__days}>
            <h3>Available Days</h3>
            <input type="radio" defaultChecked />
            <p>Days</p>
          </div>
          <div className={style.customizeSlot__right__days}>
            <h3>Week</h3>
            <FaCalendarDay color="$eight-color" className={style.customizeSlot__right__days__icon} />
            <input type="radio" defaultChecked />
            <p>Repeated</p>
          </div>
          <div className={style.customizeSlot__right__weeks}>
            {dayObj.map((item) => {
              return (
                <div
                  onClick={() => onSlotBookHandler(item.name)}
                  key={item.id}
                  className={`${style.customizeSlot__right__weeks__day} ${item.name === active ? style.active : ""}`}>
                  <p>{item.name.slice(0, 3)}</p>
                </div>
              );
            })}
          </div>

          <TimeSlot update={update} value1={start} value2={end} startTime={startTime} endTime={endTime} onClick={onTimeSlotHandler} />

          <div className={style.customizeSlot__right__newSlot}>
            {timeSlotArr?.length > 0 && <p className={style.customizeSlot__right__newSlot__head}>New Slots Added</p>}
            <div className={style.customizeSlot__right__newSlot__slot}>
              {timeSlotArr.map((item, index) => {
                return item[active]?.map((itm) => {
                  return (
                    <p
                      key={itm}
                      onClick={() => onDailySlotBookHandler(itm, active)}
                      className={`${style.customizeSlot__right__newSlot__slot__time}`}>
                      {itm}
                      <span onClick={() => onDeletSlot(itm, index)}>
                        <i className="fa-solid fa-trash"></i>
                      </span>
                    </p>
                  );
                });
              })}
            </div>
          </div>

          <div className={style.customizeSlot__right__timeSlot}>
            <div className={style.customizeSlot__right__timeSlot__slots}>
              {loading ? (
                <Loading />
              ) : (
                <>
                  {allSlots && (
                    <>
                      {Object.values(allSlots)?.map((item) => {
                        return item[active]?.map((itm, index) => {
                          return (
                            <div key={itm + index} className={`${style.customizeSlot__right__timeSlot__time}`}>
                              <p
                                onClick={() => onDailySlotBookHandler(itm, active)}
                                className={`${style.customizeSlot__right__timeSlot__time__range}`}>
                                {itm}
                                {timeSlotCheaker.length > 0 ? (
                                  <>
                                    {timeSlotCheaker.includes(itm) ? (
                                      <span className={style.notDelet}>
                                        <i className="fa-solid fa-ban"></i>
                                      </span>
                                    ) : (
                                      <span onClick={() => onDeletExistingSlot(itm, item, active)}>
                                        <i className="fa-solid fa-trash"></i>{" "}
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  <span key={item} onClick={() => onDeletExistingSlot(itm, item, active)}>
                                    <i className="fa-solid fa-trash"></i>
                                  </span>
                                )}
                              </p>

                              {item[active]?.length === "" && <p>No Slots Available</p>}
                            </div>
                          );
                        });
                      })}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default CustomizeSlot;
