import React, { useState } from "react";
import Style from "./index.module.scss";
import { Rating } from "@mui/material";
import Button from "../../widgets/Button";
import { endpoints } from "../../utils/Endpoints";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
const FeedBack = () => {
  const details = JSON.parse(localStorage.getItem("user"));
  const [value, setValue] = useState("");
  const [feedback, setFeedback] = useState("");
  const [submit, setSubmit] = useState(true);
  const navigate = useNavigate();
  const onFeedbackHandler = (e) => {
    setFeedback(e.target.value);
  };

  const onSubmitHandler = () => {
    setSubmit(false);
    const formData = new FormData();
    formData.append("feedback", feedback);
    formData.append("ratting", value);
    const data = {
      url: endpoints.submitFeedback,
      headers: {
        Authorization: `Bearer ${details?.token}`,
      },
      method: "POST",
      data: formData,
    };
    axios(data)
      .then((res) => {
        setSubmit(true);
        swal("Successful!", "Feedback Submited Successfully!", "success");
        navigate("/");
      })
      .catch((err) => {
        // console.log(err)
      });
  };
  return (
    <div className={Style.feddbackAre}>
      <div className={Style.feddbackAre__feedbackform}>
        <h2>Submit your feedback here..</h2>
        <label htmlFor="feedback">Description</label>
        <textarea
          name="feedback"
          id="feedback"
          onChange={onFeedbackHandler}
        ></textarea>
        <label htmlFor="ratting">Ratting</label>
        <div>
          <Rating
            className={Style.rating}
            name="simple-controlled"
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          />
        </div>
        <Button
          btnName={submit ? "Submit" : "Processing.."}
          width="200px"
          onClick={onSubmitHandler}
        />
      </div>
    </div>
  );
};

export default FeedBack;
