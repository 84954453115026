import React, { useState } from "react";
import Styles from "./index.module.scss";
import Button from "../../../widgets/Button";
import { Rating } from "@mui/material";
import PanditJiModal from "../../../modal/PanditJiModal";
import Modal from "../../../modal";
import ReactPlayer from "react-player";
const PoojaDetails = () => {
  const [showPanditJiModal, setShowPanditJiModal] = useState(false);
  const [price, setPrice] = useState("");
  const poojaDetail = JSON.parse(localStorage.getItem("poojaDetails"));

  const openBookPanditJiModal = (price, name) => {
    setShowPanditJiModal(true);
    setPrice(price);
  };
  const hidePanditJiModal = () => {
    setShowPanditJiModal(false);
  };

  return (
    <>
      {showPanditJiModal && (
        <Modal onClose={hidePanditJiModal} height="auto">
          <PanditJiModal
            setShowPanditJiModal={setShowPanditJiModal}
            onCloseHandler={hidePanditJiModal}
            price={price}
            poojaDet={poojaDetail}
          />
        </Modal>
      )}
      <div className={Styles.poojaDetails}>
        <div className={Styles.poojaDetails__bannerArea}>
          <p>
            Book Pandit Ji <i className="fa-solid fa-angle-right"></i>{" "}
            <span>
              {poojaDetail?.name} <i className="fa-solid fa-angle-right"></i>
            </span>
          </p>
          <h1>Book Pandit Ji</h1>
          <h4>{poojaDetail?.name}</h4>
          <div className={Styles.poojaDetails__bannerArea__rattingArea}>
            <span>
              <Rating
                name="half-rating-read"
                defaultValue={poojaDetail?.rating ? poojaDetail?.rating : "0"}
                precision={0.5}
                readOnly
              />
            </span>
            <span>(514 Ratting)</span>
          </div>
        </div>
        <div className={Styles.poojaDetails__detailsArea}>
          <div className={Styles.poojaDetails__detailsArea__title}>
            <h1>Pooja Details</h1>
            <p
              className={Styles.poojaDetails__detailsArea__title__arrowTitle}
            ></p>
          </div>
          <div className={Styles.poojaDetails__detailsArea__videoArea}>
            <div className={Styles.poojaDetails__detailsArea__videoArea__video}>
              <ReactPlayer
                playing={true}
                controls={true}
                config={{
                  file: { attributes: { controlsList: "nodownload" } },
                }}
                width="100%"
                height="100%"
                url={poojaDetail?.video_url}
              />
            </div>
            <div
              className={Styles.poojaDetails__detailsArea__videoArea__priceArea}
            >
              <div
                className={
                  Styles.poojaDetails__detailsArea__videoArea__priceArea__poojaType
                }
              >
                <h2>Rs. {poojaDetail?.online_price}/-</h2>
                <p>(ONLINE POOJA)</p>
                <Button
                  onClick={() =>
                    openBookPanditJiModal(
                      poojaDetail?.online_price,
                      poojaDetail?.name
                    )
                  }
                  marginTop="5px"
                  fontSize="18px"
                  btnName="Book Pandit Ji"
                />
              </div>
              <div
                className={
                  Styles.poojaDetails__detailsArea__videoArea__priceArea__poojaType
                }
              >
                <h2>Rs. {poojaDetail?.offline_price}/-</h2>
                <p>(OFFLINE POOJA)</p>
                <Button
                  onClick={() =>
                    openBookPanditJiModal(
                      poojaDetail?.offline_price,
                      poojaDetail?.name
                    )
                  }
                  marginTop="5px"
                  fontSize="18px"
                  btnName="Book Pandit Ji"
                />
              </div>
              <div
                className={
                  Styles.poojaDetails__detailsArea__videoArea__priceArea__shareArea
                }
              >
                {/* <Button
                  fontSize="18px"
                  marginBottom="0"
                  btnName="GIFT TO A FRIEND"
                />
                <Button fontSize="18px" marginBottom="0" btnName="SHARE" />
                <Button
                  fontSize="18px"
                  marginBottom="0"
                  btnName="APPLY COUPON"
                /> */}
              </div>
            </div>
          </div>
          <div className={Styles.poojaDetails__detailsArea__descriptionArea}>
            <div
              className={
                Styles.poojaDetails__detailsArea__descriptionArea__cardArea
              }
            >
              <h1>
                <i className="fa-solid fa-hands-praying"></i> Pooja Description{" "}
              </h1>
              <p>{poojaDetail?.description}</p>
            </div>
            <div
              className={
                Styles.poojaDetails__detailsArea__descriptionArea__cardArea
              }
            >
              <h1>
                <i className="fa-solid fa-hands-praying"></i> Samagri Required{" "}
              </h1>
              <div dangerouslySetInnerHTML={{ __html: poojaDetail?.samagri }} />
            </div>
            <div
              className={
                Styles.poojaDetails__detailsArea__descriptionArea__cardArea
              }
            >
              <h1>
                <i className="fa-solid fa-hands-praying"></i> What You Will Get{" "}
              </h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: poojaDetail?.what_get_description,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PoojaDetails;
