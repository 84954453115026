import React from "react";
import Styles from "./index.module.scss";
import cardImg from "../../../assets/c7.webp";
import { Rating } from "@mui/material";
const VideoCard = (props) => {
  return (
    <div className={Styles.cardArea} onClick={props.onClick}>
      <div className={Styles.cardArea__imgArea}>
        <img src={props.src ? props.src : cardImg} alt="err" />
        <span>
          <i className="fa-solid fa-circle-play"></i>
        </span>
      </div>
      <div className={Styles.cardArea__detailArea}>
        <h2>{props.tittle}</h2>
        <p>{props.description}</p>
        <span>
          <Rating
            name="half-rating-read"
            defaultValue={props.ratting}
            precision={0.5}
            readOnly
          />
        </span>
        <div className={Styles.cardArea__detailArea__notification}>
          <span>{props.videoDate}</span>
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
