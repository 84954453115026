import React from "react";
import styles from "./index.module.scss";
import { IoClose } from "react-icons/io5";

const Header = ({ onCloseChat, name, tense, startChat, count, startCount, picture }) => {
  const offline = tense === "future" || tense === "past" || tense === "present";
  const on = tense === "present" && startChat;

  return (
    <div className={styles.header}>
      <div className={styles.header__left}>
        <div className={styles.header__left__user}>
          <img src={picture} alt="user" />
        </div>
        <div>
          <h2 className={styles.header__left__username}>{name}</h2>
          <div className={styles.header__left__div}>
            <p style={{ background: on ? "green" : offline ? "red" : "red" }} className={styles.header__left__div__status}></p>
            <p className={styles.header__left__div__userStatus}>Chat Consultation</p>
          </div>
        </div>
      </div>

      <div onClick={onCloseChat} className={styles.header__right}>
        {count !== 0 && startCount && (
          <div>
            <p>{count}</p>
          </div>
        )}
        <IoClose color="#fff" fontSize={20} />
      </div>
    </div>
  );
};

export default Header;
