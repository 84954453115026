import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import Header from "./Header";
import Footer from "./Footer";
import Incoming from "./Incoming";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { Context } from "../../utils/ContextStor";
import Outgoing from "./Outgoing";
import Loading from "../../components/Loading";

const Chat = ({ onCloseChat, allMessages, items, db,innerRef }) => {
  // eslint-disable-next-line
  const [token, setToken, updatedUser, setUpdatedUser] = useContext(Context);
  const containerRef = useRef(null);
  const [tense, setTense] = useState("future");
  const [startChat, setStartChat] = useState(false);
  const [startCount, setStartCount] = useState(false);
  const [chatComplete, setChatComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(60);

  let user = JSON.parse(localStorage.getItem("user"));

  const sortedData = allMessages.sort((a, b) => {
    const timeA = new Date(a?.timestamp?.seconds);
    const timeB = new Date(b?.timestamp?.seconds);

    return timeA - timeB;
  });

  useEffect(() => {
    // if (containerRef.current) {
    //   console.log(containerRef.current.scrollHeight);
    //   setContainerHeight(containerRef.current.scrollHeight);
    // }

    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [loading]);

  const [message, setMessage] = useState("");
  const sendMessage = async (roomId, user, text) => {
    setLoading(true);

    try {
      await addDoc(collection(db, "chat-consultation", `${items.customer_id}${items.astrologer_id}`, "messages"), {
        uid: updatedUser.id,
        displayName: user?.user_type === "Astrologer" ? items.astrologer_name : items.customer_name,
        text: message,
        currTime: new Date().toLocaleTimeString("en-US", { hour12: true }),
        timestamp: serverTimestamp(),
      });

      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
    setMessage("");
  };

  const d = items.date.split("-");
  // const dt = "14-03-2024";
  // const d = dt.split("-");
  const bookDate = new Date(d[2], d[1] - 1, d[0]).toDateString();

  useEffect(() => {
    if (bookDate < new Date().toDateString()) {
      setTense("past");
    } else if (bookDate > new Date().toDateString()) {
      setTense("future");
    } else {
      setTense("present");
    }
    // eslint-disable-next-line
  }, [startChat]);
  // const time = "11:35AM-11:36 AM";

  const times = items.slot_time.split("-");
  // const times = time.split("-");

  const convertTo24HourFormat = (val) => {
    let timeArray = val.split(":");
    let hour = parseInt(timeArray[0]);
    let minute = timeArray[1].split(" ")[0];
    let session = timeArray[1].split(" ")[1];

    if (session === "PM" && hour !== 12) {
      hour += 12;
    } else if (session === "AM" && hour === 12) {
      hour = 0;
    }

    let updatedHour = hour < 10 ? "0" + hour : hour;

    let time24Hour = `${updatedHour}:${minute}`;

    return time24Hour;
  };

  const startTime = convertTo24HourFormat(times[0]);
  const endTime = convertTo24HourFormat(times[1]);

  const checkAvailability = (currMin, currHour) => {
    const startMin = parseInt(startTime.split(":")[1]);
    const startHour = parseInt(startTime.split(":")[0]);
    const endMin = parseInt(endTime.split(":")[1]);
    const endHour = parseInt(endTime.split(":")[0]);

    const startT = startHour * 60 + startMin;
    const endT = endHour * 60 + endMin;

    const currentT = currHour * 60 + currMin;

    if (currentT >= startT && currentT <= endT) {
      if (currentT === endT) {
        if (currentT === endT + 1) {
          setStartChat(false);
        } else {
          setStartCount(true);
          setCount(count - 1);
        }
      } else {
        setStartChat(true);
      }
    } else if (currentT > endT) {
      setChatComplete(true);
      setStartChat(false);
    } else {
      setStartChat(false);
      setChatComplete(false);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const date = new Date();
      const currHour = parseInt(date.getHours());
      const currMin = parseInt(date.getMinutes());

      checkAvailability(currMin, currHour);
    }, 1000);

    if (count === 1) {
      setInterval(timer);
    }

    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [count]);

  // useEffect(() => {
  //   let timer;
  //   if (startCount) {
  //     timer = setTimeout(() => {
  //       setCount(count - 1);
  //     }, 1000);

  //     if (count === 0) {
  //       clearTimeout(timer);
  //     }
  //   }
  //   return () => clearTimeout(timer);
  // }, [count]);

  return (
    <div className={styles.chat} ref={innerRef}>
      <Header
        startCount={startCount}
        count={count}
        startChat={startChat}
        tense={tense}
        onCloseChat={onCloseChat}
        picture={user.user_type === "Astrologer" ? items.profile_pic_image : items.profile_pic_image_astrologer}
        name={user.user_type === "Astrologer" ? items.customer_name : items.astrologer_name}
      />
      <div className={styles.chat__mid} ref={containerRef}>
        {loading ? (
          <Loading />
        ) : (
          <>
            {allMessages.length > 0 ? (
              sortedData?.map((msg) => {
                return msg.uid === updatedUser.id ? (
                  <Outgoing key={msg.currTime} outgoing={msg.text} oTime={msg.currTime} />
                ) : (
                  <Incoming incoming={msg.text} iTime={msg.currTime} />
                );
              })
            ) : (
              <div className={styles.chat__mid__content}>
                <p>No Messages!</p>
              </div>
            )}
          </>
        )}
      </div>
      <Footer chatComplete={chatComplete} startChat={startChat} message={message} tense={tense} items={items} setMessage={setMessage} sendMessage={sendMessage} />
    </div>
  );
};

export default Chat;
