import React from "react";
import Style from "./index.module.scss";
// import noData from "../../../assets/1711628998_2678.png";
const PanditList = ({ img, name, service, experience }) => {
  return (
    <>
      <div className={Style.listCard}>
        <div className={Style.listCard__image}>
          <img src={img} alt="img" />
        </div>
        <div className={Style.listCard__detail}>
          <h4>{name}</h4>
          <h6>{service}</h6>
          <p>
            <span>Exp.</span> {experience} Year
          </p>
        </div>
      </div>
    </>
  );
};

export default PanditList;
