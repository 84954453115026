import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import style from "./index.module.scss";
import Button from "../../widgets/Button";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useContext } from "react";
import { Context } from "../../utils/ContextStor";
import Login from "../Login";
import Modal from "..";
import { useCallback } from "react";
import SignUp from "../SignUp";
import swal from "sweetalert";
import moment from "moment";
import noDataFoundImg from "../../assets/noData.png";
const BookSlot = (props) => {
  const userDetails = JSON.parse(localStorage.getItem("user"));
  // eslint-disable-next-line
  const [token, setToken] = useContext(Context);

  let newAstroData = JSON.parse(localStorage.getItem("allConsultationData"));
  let astroId = JSON.parse(localStorage.getItem("astroDet"));
  // let user = JSON.parse(localStorage.getItem("user"));
  const [selectedDate, setSelectedDate] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);
  const [timeSlotsChecker, setTimeSlotsChecker] = useState([]);
  const [selectedTime, setSelectedTime] = useState("");
  const [currentDate, setCurrentDate] = useState();
  const params = useParams();
  const navigate = useNavigate();
  const [slot, setSlot] = useState([]);
  const [loginModal, setLoginModal] = useState(false);
  const [signup, setSignup] = useState(false);

  const [submit, setSubmit] = useState(true);
  // *************** Current Date ****************************
  useEffect(() => {
    let today = new Date();

    if (today.getDay() === 0) {
      setCurrentDate("Sunday");
    } else if (today.getDay() === 1) {
      setCurrentDate("Monday");
    } else if (today.getDay() === 2) {
      setCurrentDate("Tuesday");
    } else if (today.getDay() === 3) {
      setCurrentDate("Wednesday");
    } else if (today.getDay() === 4) {
      setCurrentDate("Thursday");
    } else if (today.getDay() === 5) {
      setCurrentDate("Friday");
    } else if (today.getDay() === 6) {
      setCurrentDate("Saturday");
    }
  }, []);

  //********************** slot-checker ******************************/

  useEffect(() => {
    const config = {
      url: `https://admin.adhigyanam.com/api/slot-checker`,
      method: "post",
      headers: {
        Authorization: `Bearer ${userDetails?.token}`,
      },
      data: {
        astrologer_id: astroId.astrologerID,
        slot_date: selectedDate,
      },
    };
    axios(config)
      .then((res) => {
        setTimeSlotsChecker(res.data.data);
      })
      .catch((err) => {
        // console.log(err)
      });
    // eslint-disable-next-line
  }, [selectedDate]);

  const handleDateChange = (date) => {
    setSelectedTime("");
    let userDate = new Date(date);
    if (userDate.getDay() === 0) {
      setCurrentDate("Sunday");
    } else if (userDate.getDay() === 1) {
      setCurrentDate("Monday");
    } else if (userDate.getDay() === 2) {
      setCurrentDate("Tuesday");
    } else if (userDate.getDay() === 3) {
      setCurrentDate("Wednesday");
    } else if (userDate.getDay() === 4) {
      setCurrentDate("Thursday");
    } else if (userDate.getDay() === 5) {
      setCurrentDate("Friday");
    } else if (userDate.getDay() === 6) {
      setCurrentDate("Saturday");
    }
    let dateeeee = userDate.getMonth() + 1;
    let userSelectedDate = `${userDate.getDate() > 9 ? userDate.getDate() : "0" + userDate.getDate()}-${dateeeee > 9 ? dateeeee + 1 : "0" + dateeeee
      }-${userDate.getFullYear()}`;

    setSelectedDate(userSelectedDate);

    if (newAstroData) {
      newAstroData.slot_date = userSelectedDate;
      localStorage.setItem("allConsultationData", JSON.stringify(newAstroData));
    }

    // ****************************************************************
    generateTimeSlots();
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
    if (newAstroData) {
      newAstroData.slot_time = time;
      localStorage.setItem("allConsultationData", JSON.stringify(newAstroData));
    }
  };

  const handleBookNow = () => {
    setSubmit(false);

    if (params.type === "Individual") {
      if (selectedTime === "") {
        swal("Invalid!", "Please Select Time!", "error");
        setSubmit(true);
      } else if (selectedDate === "") {
        swal("Invalid!", "Please Select Date!", "error");
        setSubmit(true);
      } else {
        const path = generatePath(`/astrology/${params.id}/${params.name}/${params.type}/type1/book-slot/form`);
        navigate(path);
      }
    }
  };

  const onCloseModal = () => {
    setLoginModal(false);
    setSignup(false);
  };

  const onLogin = useCallback(async (userDetails) => {
    setLoginModal(false);
    setToken(userDetails.token);
    const filteredData = {
      name: userDetails.name,
      email: userDetails.email,
      token: userDetails.token,
      profile_ic: userDetails.profile_ic,
      user_type: userDetails.user_type,
      speacility: userDetails.speacility,
      experience: userDetails.experience,
      id: userDetails.id,
      date_of_birth: userDetails.date_of_birth,
      place_of_birth: userDetails.place_of_birth,
      time_of_birth: userDetails.time_of_birth,
      gender: userDetails.gender,
      contact_no: userDetails.contact_no,
      language: userDetails.language,
    };
    localStorage.setItem("token", userDetails.token);
    localStorage.setItem("user", JSON.stringify(filteredData));
    localStorage.setItem("profilePicture", JSON.stringify(filteredData.profile_ic));
    if (userDetails.user_type === "Astrologer" || userDetails.user_type === 1) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let userDetails = JSON.parse(user);

    if (userDetails && userDetails.token) {
      onLogin(userDetails);
    }
  }, [onLogin]);
  useEffect(() => {
    axios
      .get(`https://admin.adhigyanam.com/api/timeslot-get/${astroId.astrologerID}`)
      .then((res) => {
        setSlot(res.data.data);
      })
      .catch((err) => {
        // console.log(err)
      });
    // eslint-disable-next-line
  }, []);

  const generateTimeSlots = () => {
    let filterData = slot.filter((item) => item.day === currentDate);
    let selectTime = filterData[0]?.slots[currentDate]?.map((item) => {
      return item;
    });
    let showSlot = selectTime?.map((item) => {
      return item;
    });
    setTimeSlots([showSlot]);
  };

  let remainingTimeSlots;
  const curDate = new Date();
  const currDate = curDate.getDate();
  const currMonth = curDate.getMonth() + 1;
  const currYear = curDate.getFullYear();

  let current = `${currDate > 9 ? currDate : "0" + currDate}-${currMonth > 9 ? currMonth : "0" + currMonth}-${currYear}`;
  if (selectedDate === current) {
    const sortByTime = (a, b) => {
      const format = "hh:mm A";

      const timeA = moment(a.split("-")[0], format);
      const timeB = moment(b.split("-")[0], format);

      return timeA.isBefore(timeB) ? -1 : 1;
    };

    const sortedTimeStrings = timeSlots[0]?.sort(sortByTime);

    const currentTime = moment();

    const futureTimeSlots = sortedTimeStrings?.filter((timeStr) => {
      const time = moment(timeStr.split("-")[0], "hh:mm A");
      return time.isAfter(currentTime);
    });

    remainingTimeSlots = futureTimeSlots?.filter((time) => !timeSlotsChecker.includes(time));
  } else {
    const sortByTime = (a, b) => {
      const format = "hh:mm A";

      const timeA = moment(a.split("-")[0], format);
      const timeB = moment(b.split("-")[0], format);

      return timeA.isBefore(timeB) ? -1 : 1;
    };

    const sortedTimeStrings = timeSlots[0]?.sort(sortByTime);

    // const currentTime = moment();

    // const futureTimeSlots = sortedTimeStrings?.filter((timeStr) => {
    //   const time = moment(timeStr.split("-")[0], "hh:mm A");
    //   return time.isAfter(currentTime);
    // });

    remainingTimeSlots = sortedTimeStrings?.filter((time) => !timeSlotsChecker.includes(time));
  }

  useEffect(() => {
    generateTimeSlots();
    // eslint-disable-next-line
  }, [slot, currentDate]);

  const onSignupHandler = () => {
    setLoginModal(false);
    setSignup(true);
  };
  const onCancelSignUp = () => {
    setLoginModal(true);
    setSignup(false);
  };
  const onSelectSlotHandler = () => {
    if (selectedTime === "") {
      swal("Invalid!", "Please Select Time!", "error");
      setSubmit(true);
    } else if (selectedDate === "") {
      swal("Invalid!", "Please Select Date!", "error");
      setSubmit(true);
    } else {
      props.onClick(selectedDate, selectedTime);
    }
  };
  const onNavigateBackHandler = () => {
    if (params.type === "Married Life Analysis") {
      navigate(`/astrology/${params.id}/${params.name}/${params.type}/type3`);
    } else if (params.type === "Match Making") {
      navigate(`/astrology/${params.id}/${params.name}/${params.type}/type2`);
    } else if (params.type === "kundli") {
      navigate(`/astrology/${params.id}/${params.name}/${params.type}/type5`);
    } else if (params.type === "Individual") {
      navigate(`/astrology/${params.id}/${params.name}/${params.type}/type1`);
    }
  };
  return (
    <>
      {loginModal && (
        <Modal onClose={onCloseModal}>
          <Login onSignupHandler={onSignupHandler} onCloseHandler={onCloseModal} onLoginHandler={onLogin} />
        </Modal>
      )}
      {signup && (
        <Modal onClose={onCloseModal}>
          <SignUp setSignup={setSignup} onCloseHandler={onCloseModal} onCancelSignUp={onCancelSignUp} />
        </Modal>
      )}
      <div style={{ padding: `${props.padding}` }} className={style.bookSlot}>
        <div className={style.bookSlot__backBtn} onClick={onNavigateBackHandler} style={{ display: `${props.display}` }}>
          <i className="fa-solid fa-left-long"></i>
        </div>
        <div style={{ width: `${props.width}` }} className={style.bookSlot__container}>
          {/* <Close /> */}
          <div className={style.bookSlot__container__top}>
            <h1>Book Your Slot</h1>
            <div className={style.bookSlot__container__top__btn}>
              {props.modalIsVisible ? (
                <Button
                  onClick={onSelectSlotHandler}
                  marginTop="0"
                  height="40px"
                  marginBottom="0"
                  btnName="Next"
                  textTransform="capitalize"
                />
              ) : (
                <Button
                  onClick={handleBookNow}
                  marginTop="0"
                  height="40px"
                  btnName={submit ? "Book" : "Booking.."}
                  textTransform="capitalize"
                />
              )}
            </div>
          </div>
          <div className={style.bookSlot__container__content}>
            <div className={style.bookSlot__container__content__calender}>
              <Calendar
                className={style.bookSlot__container__content__calender__cal}
                onChange={handleDateChange}
                value={selectedDate}
                minDate={new Date()}
              />
            </div>

            <div className={style.bookSlot__container__content__timeSlot}>
              <div className={style.bookSlot__container__content__timeSlot__slot}>
                <p className={style.bookSlot__container__content__timeSlot__slot__utc}>India Standard Time (IST)</p>
                <h3 className={style.bookSlot__container__content__timeSlot__slot__book}>Book A Time Slot</h3>
                <p className={style.bookSlot__container__content__timeSlot__slot__best}>What Time Work Best?</p>
                <ul className={style.bookSlot__container__content__timeSlot__slot__ul}>
                  <>
                    {timeSlots[0] === undefined || remainingTimeSlots < 1 ? (
                      <span className={style.emptyMsg}>
                        <img src={noDataFoundImg} alt="err" />
                      </span>
                    ) : (
                      <>
                        {remainingTimeSlots?.map((slot, index) => (
                          <li
                            key={index}
                            onClick={() => handleTimeSelect(slot)}
                            className={`${style.bookSlot__container__content__timeSlot__slot__ul__li} ${selectedTime === slot ? style.active : ""
                              } `}>
                            {slot}
                          </li>
                        ))}
                      </>
                    )}
                  </>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookSlot;
