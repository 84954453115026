import React, { useEffect, useState } from "react";
import style from "./index.module.scss";
import { useLocation } from "react-router-dom";
import { Rating } from "@mui/material";
import swal from "sweetalert";
import Button from "../../../widgets/Button";
import Calendar from "react-calendar";
import noDataFoundImg from "../../../assets/noData.png";
import axios from "axios";
const RegisterDoubtSession = (props) => {
  let location = useLocation();
  // const [courseDetails, setCourseDetails] = useState(location.state.courseData);
  const [submit, setSubmit] = useState(true);
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [slotArr, setSlotArr] = useState([]);
  const [slot, setSlote] = useState([]);
  const courseDetails = location?.state?.courseData;
  let user = JSON.parse(localStorage.getItem("user"));
  let token = user.token;

  const [slotObj, setSloteObj] = useState([]);
  const purchaseDetails = location?.state?.purchaseDetail;

  useEffect(() => {
    let formData = new FormData();
    formData.append("date", selectedDate);
    let getSlot = {
      url: "https://admin.adhigyanam.com/api/slot-for-doubt-session",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    };

    axios(getSlot)
      .then((res) => {
        setSlote(res.data.data);
      })
      .catch((err) => {
        // console.log(err)
      });

    // eslint-disable-next-line
  }, [selectedDate]);

  const handleDateChange = (date) => {
    setSelectedTime("");
    let userDate = new Date(date);

    let dateeeee = userDate.getMonth() + 1;
    let userSelectedDate = `${userDate.getDate() > 9 ? userDate.getDate() : "0" + userDate.getDate()}-${dateeeee > 9 ? dateeeee + 1 : "0" + dateeeee
      }-${userDate.getFullYear()}`;

    setSelectedDate(userSelectedDate);
  };

  const onSelectSlotHandler = () => { };
  const handleBookNow = () => {
    setSubmit(false);
    // ******************************************************Purchase Packages Code************************************************************

    if (slotObj.length <= 0) {
      setSubmit(true);
      swal("Please Select Slot!", "Please Select Slot!", "error");
    } else {
      let formData = new FormData();
      formData.append("package_id", purchaseDetails.package_id);
      formData.append("price", purchaseDetails.price);
      formData.append("is_doubt", purchaseDetails.is_doubt);
      formData.append("slots", slotObj);
      formData.append("slot_array", slotArr);
      let pakageData = {
        url: "https://admin.adhigyanam.com/api/make-course-payment",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: formData,
      };
      axios(pakageData)
        .then((res) => {
          window.location.replace(res.data.message);
          setSubmit(true);
        })
        .catch((err) => {
          // console.log(err)
        });
    }
  };
  const handleTimeSelect = (time, id) => {
    setSelectedTime(time);
    let isExistDate = slotObj.find((slot) => slot.date === selectedDate);
    if (selectedDate === "") {
      swal("Please select diffrent date", "Please select diffrent date", "error");
    } else if (slotObj.length > 3) {
      swal("You can only choose 4 slot", "You can only choose 4 slot", "error");
    } else if (isExistDate?.date === selectedDate) {
      swal("You can not select same date again!", "You can not select same date again!", "error");
    } else {
      let chooseSlot = {
        date: selectedDate,
        time: time,
      };
      setSloteObj([...slotObj, chooseSlot]);
      setSlotArr([...slotArr, id]);
    }
  };

  return (
    <div className={style.courseDetails}>
      <div className={style.courseDetails__bannerArea}>
        <div className={style.courseDetails__bannerArea__container}>
          <p>
            Adhigyanam Courses <i className="fa-solid fa-angle-right"></i>{" "}
            <span>
              Category <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>{courseDetails.title}</span>
          </p>
          <h1>{courseDetails.title}</h1>
          <h4>{courseDetails.sub_title}</h4>
          <h5>
            Tought By : <span>{courseDetails.taught_by}</span>
          </h5>
          <h5>
            Language : <span>{courseDetails.language}</span>
          </h5>
          <div className={style.courseDetails__bannerArea__container__rattingArea}>
            <span>
              <Rating
                name="half-rating-read"
                defaultValue={courseDetails?.ratings ? courseDetails?.ratings : "0"}
                precision={0.5}
                readOnly
              />
            </span>
            <span>({courseDetails?.rating_count})</span>
          </div>
        </div>
      </div>
      <div className={style.courseDetails__detailsArea}>
        <div style={{ padding: `${props.padding}` }} className={style.bookSlot}>
          <div style={{ width: `${props.width}` }} className={style.bookSlot__container}>
            <div className={style.bookSlot__container__top}>
              <h1>Book Your Slot</h1>
              <div className={style.bookSlot__container__top__btn}>
                {props.modalIsVisible ? (
                  <Button
                    onClick={onSelectSlotHandler}
                    marginTop="0"
                    height="40px"
                    marginBottom="0"
                    btnName="Next"
                    textTransform="capitalize"
                  />
                ) : (
                  <Button
                    onClick={handleBookNow}
                    marginTop="0"
                    height="40px"
                    btnName={submit ? "Book" : "Booking.."}
                    textTransform="capitalize"
                  />
                )}
              </div>
            </div>
            <div className={style.bookSlot__container__content}>
              <div className={style.bookSlot__container__content__calender}>
                <Calendar
                  className={style.bookSlot__container__content__calender__cal}
                  onChange={handleDateChange}
                  value={selectedDate}
                  minDate={new Date()}
                />
              </div>

              <div className={style.bookSlot__container__content__timeSlot}>
                <div className={style.bookSlot__container__content__timeSlot__slot}>
                  <p className={style.bookSlot__container__content__timeSlot__slot__utc}>India Standard Time (IST)</p>
                  <h3 className={style.bookSlot__container__content__timeSlot__slot__book}>Book A Time Slot</h3>
                  <p className={style.bookSlot__container__content__timeSlot__slot__best}>What Time Work Best?</p>
                  <ul className={style.bookSlot__container__content__timeSlot__slot__ul}>
                    <>
                      {slot.length < 1 ? (
                        <span className={style.emptyMsg}>
                          <img src={noDataFoundImg} alt="err" />
                        </span>
                      ) : (
                        <>
                          {slot?.map((slot, index) => (
                            <li
                              key={index}
                              onClick={() => handleTimeSelect(slot.slot, slot.id)}
                              className={`${style.bookSlot__container__content__timeSlot__slot__ul__li} ${selectedTime === slot ? style.active : ""
                                } `}>
                              {slot.slot}
                            </li>
                          ))}
                        </>
                      )}
                    </>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.courseDetails__detailsArea__allslotArea}>
          <ul>
            {slotObj.map((item) => {
              return <li>{`${item.date} - ${item.time}`}</li>;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RegisterDoubtSession;
